import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../../components/Layout";

export default () => (
  <StaticQuery
    query={graphql`
      query ContactFormSent {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            contactForm {
              sent {
                title
                message
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { frontmatter } = data.markdownRemark;
      return (
        <>
          <Layout data={data}>
            <section className="section">
              <div className="container">
                <div className="content">
                  <h1>{frontmatter.contactForm.sent.title}</h1>
                  <p>{frontmatter.contactForm.sent.message}</p>
                </div>
              </div>
            </section>
          </Layout>
        </>
      );
    }}
  />
);
